module.exports = {
  siteTitle: "crsantos - iOS Developer 📱", // <title>
  shortSiteTitle: "crsantos - iOS Developer 📱", // <title> ending for posts and pages
  siteDescription: "crsantos - iOS Developer 📱",
  siteUrl: "https://crsantos.info",
  pathPrefix: "",
  siteImage: "preview.jpg",
  siteLanguage: "en",
  // author
  authorName: "crsantos",
  authorTwitterAccount: "crsantos",
  // info
  headerTitle: "Carlos Santos",
  headerSubTitle: "iOS Dev",
  // manifest.json
  manifestName: "crsantos",
  manifestShortName: "crsantos", // max 12 characters
  manifestStartUrl: "/index.html",
  manifestBackgroundColor: "white",
  manifestThemeColor: "#666",
  manifestDisplay: "standalone",
  // social
  authorSocialLinks: [
    {
      name: "github",
      url: "https://github.com/crsantos"
    }, {
      name: "twitter",
      url: "https://twitter.com/crsantos"
    }
  ]
};
